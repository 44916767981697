import React, { RefObject, useState, ChangeEvent } from "react";
import { useRef } from "react";
import { nanoid } from "nanoid";
import "./upload-button.scss";

export const UploadButton = ({
  name,
  onChange,
}: {
  name: string;
  label: string;
  onChange?: () => void;
}) => {
  const id = useRef(name || nanoid(8));
  const inputRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState("");
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setFileName(file ? file.name : "");
    onChange?.();
  };

  return (
    <div className="upload-button">
      <label className="file-label"></label>
      <input
        name={name}
        style={{ display: "none" }}
        ref={inputRef}
        accept=".pdf"
        type="file"
        id={id.current}
        onChange={handleFileChange}
      />
      <button
        className="button"
        type="button"
        onClick={() => inputRef.current?.click()}
      >
        Last opp CV *
      </button>

      <span className="file-name" style={{ marginLeft: "10px" }}>
        {fileName || "No file selected"}
      </span>
    </div>
  );
};
