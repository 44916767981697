import React, { useRef } from "react";
import { nanoid } from "nanoid";
import styles from "./inputs.module.scss";
import { ILabeledInputProps } from "../../interfaces/input.interfaces";

const BaseInput = React.forwardRef<HTMLInputElement, ILabeledInputProps>(
  ({ label, type = "text", ...props }, ref) => {
    const id = useRef(props.name || nanoid(8));

    return (
      <div className={styles.wrapper}>
        <input
          type={type}
          className="input-text"
          {...props}
          id={id.current}
          ref={ref}
        />
        <label className="input-label" htmlFor={id.current}>
          {label}
        </label>
      </div>
    );
  }
);

BaseInput.displayName = "BaseInput";

export default BaseInput;
