import facebook from "../assets/icons/facebook.png";
import linkedin from "../assets/icons/linkedin.png";
import instagram from "../assets/icons/instagram.png";
import design from "../assets/images/design.svg";
import mobile from "../assets/images/mobile.svg";
import front_end from "../assets/images/front_end.svg";
import back_end from "../assets/images/back_end.svg";
import question_mark from "../assets/images/question_mark.svg";
import consultancy from "../assets/images/consultancy.svg";
import bilde from "../assets/images/testimonials/bilde.jpg";
import ibm from "../assets/images/ibm.svg";
import solid_logo from "../assets/images/testimonials/solid_logo.svg";
import profile from "../assets/images/testimonials/test-portret.png";
import axo_finans_logo_bw from "../assets/images/clients/axo-finans_logo.svg";
import lt_logo_bw from "../assets/images/clients/lt_logo.svg";
import bank_logo_bw from "../assets/images/clients/bank_logo.svg";
import bufdir_logo_bw from "../assets/images/clients/bufdir_logo.svg";
import sector_logo_bw from "../assets/images/clients/sector-alarm_logo.svg";
import spond_logo_bw from "../assets/images/clients/spond_logo.svg";
import ruter_logo_bw from "../assets/images/clients/ruter_logo.svg";
import morgenlevering_logo_bw from "../assets/images/clients/morgenlevering_logo.svg";
import {
  ILinkItemProps,
  ILinkItemPropsWithIcon,
} from "../interfaces/link.interfaces";
import {
  ICardProps,
  IServiveCardProps,
  ITestimonialsCardProps,
} from "../interfaces/card.interfaces";
import { Fullstack } from "../pages/service-details/details-content/fullstack";
import React from "react";
import { Devops } from "../pages/service-details/details-content/devops";
import { Frontend } from "../pages/service-details/details-content/frontend";
import { Rådgivning } from "../pages/service-details/details-content/rådgivning";
import { Backend } from "../pages/service-details/details-content/backend";

export const MENU_ITEMS: ILinkItemProps[] = [
  { link: "/", name: "Hjem" },
  { link: "/om-oss", name: "Hvem er Yne?" },
  { link: "/prosjekter", name: "Prosjekter" },
  { link: "/tjenester", name: "Tjenester" },
  { link: "/jobb", name: "Jobb" },
  // { link: "/lonnskalkulator", name: "Lønnskalkulator" },
  { link: "/blogg", name: "Blogg" },
  { link: "/kontakt-oss", name: "Kontakt oss" },
];

export const SOCIAL_LINKS: ILinkItemPropsWithIcon[] = [
  {
    link: "https://www.linkedin.com/company/67283810/",
    icon: linkedin,
    name: "linkedin",
  },
  {
    link: "https://www.facebook.com/Yne-102997078446294",
    icon: facebook,
    name: "facebook",
  },
  {
    link: "https://www.instagram.com/vi_er_yne/",
    icon: instagram,
    name: "instagram",
  },
];

export const SERVICE_INFO: IServiveCardProps[] = [
  {
    icon: consultancy,
    title: "Fullstack-utvikling",
    info: "Vi har fokus på faglig utvikling og kunnskapsdeling. Mange av våre konsulenter mestrer både frontend og backendoppgaver, i et mangfold av ulike teknologier, og har evnen til å skape sømløse brukeropplevelser ved å integrere frontend og backend på en effektiv måte ",
    link: "/tjenester/fullstack",
  },
  {
    icon: back_end,
    title: "Backend-utvikling",
    info: "Vi leverer solide backend-løsninger med høy kodekvalitet. Løsningene vi leverer er sikre, pålitelige, stabile og robuste. Vi har lang erfaring med moderne teknologistacks og  har jobbet med arkitektur av komplekse virksomhetskritiske systemer i mange år.",
    link: "/tjenester/backend",
  },
  {
    icon: question_mark,
    title: "Rådgivning",
    info: "Vi har kompetente rådgivere som kan hjelpe til med IT-tjenester og digitalisering der det er behov. Våre rådgivere har jobbet i mange år innenfor forskjellige domener, men vi har spesielt lang erfaring innenfor netthandel og fintech.",
    link: "/tjenester/raadgivning",
  },
  {
    icon: front_end,
    title: "Frontend-utvikling",
    info: "Vi leverer moderne frontend-løsninger som er funksjonelle og brukervennlige. Løsningene våre er snappy, raske og ikke minst pene å se på! Våre utviklere har sansen for det estetiske, men samtidig har vi tyngden på teknologien.",
    link: "/tjenester/frontend",
  },
  {
    icon: mobile,
    title: "DevOps",
    info: "Vi kan skytjenester og automatisering av bygg og deploy. Våre konsulenter har praktisk erfaring i hvordan man med å bruke DevOps-prinsipper og -praksiser i sitt daglige virke har fått til økt effektivitet, raskere og mer verdiskapende leveranser og reduserte kostnader. Vi har erfaring med både Azure og AWS.",
    link: "/tjenester/devops",
  },
  {
    icon: design,
    title: "Design",
    info: "Vi brenner for brukervennlighet og brukeropplevelse. Vi er gode på visuell utforming av logo, identitetsdesign og branding, UX-design, UI-design og Universell Utforming. Vi er også dyktige på illustrasjoner og informasjonsgrafikk.",
    link: "/tjenester/design",
  },
];

export const TESTIMONIALS: ITestimonialsCardProps[] = [
  {
    index: 0,
    logo: solid_logo,
    title: "Veldig fornøyd",
    text: "Vi er veldig fornøyd med designet Yne leverte til oss. De var veldig grundige i både avklaringer og med selve leveransen. Vi kan anbefale Yne og bruker de gjerne i fremtiden. Vi følte oss veldig trygge gjennom hele prosessen.",
    picture: bilde,
    name: "Dominika Janowicz",
    position: "HR konsulent",
    twitter: "",
    linkedin: "https://www.linkedin.com/in/dominika-janowicz-779b6589/",
    facebook: "",
  },
  {
    index: 1,
    logo: ibm,
    title: "Really awesome",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    picture: profile,
    name: "Trevor Johnson",
    position: "Product  manager",
    twitter: "https://twitter.com/home",
    linkedin: "https://www.linkedin.com/feed/",
    facebook: "https://www.facebook.com/",
  },
  {
    index: 2,
    logo: "",
    title: "Really awesome",
    text: "Vi er veldig fornøyd med designet Yne leverte til oss. De var veldig grundige i både avklaringer og med selve leveransen. Vi kan anbefale Yne og bruker de gjerne i fremtiden. Vi følte oss veldig trygge gjennom hele prosessen.",
    picture: bilde,
    name: "",
    position: "HR konsulent",
    twitter: "",
    linkedin: "",
    facebook: "",
  },
  {
    index: 3,
    logo: ibm,
    title: "Really awesome",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    picture: profile,
    name: "Trevor Johnson",
    position: "Product  manager",
    twitter: "https://twitter.com/home",
    linkedin: "https://www.linkedin.com/feed/",
    facebook: "https://www.facebook.com/",
  },
  {
    index: 4,
    logo: "",
    title: "Really awesome",
    text: "Vi er veldig fornøyd med designet Yne leverte til oss. De var veldig grundige i både avklaringer og med selve leveransen. Vi kan anbefale Yne og bruker de gjerne i fremtiden. Vi følte oss veldig trygge gjennom hele prosessen.",
    picture: bilde,
    name: "",
    position: "HR konsulent",
    twitter: "",
    linkedin: "",
    facebook: "",
  },
  {
    index: 5,
    logo: ibm,
    title: "Really awesome",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    picture: profile,
    name: "Trevor Johnson",
    position: "Product  manager",
    twitter: "https://twitter.com/home",
    linkedin: "https://www.linkedin.com/feed/",
    facebook: "https://www.facebook.com/",
  },
];

export const COUNTRY_INFO = [
  {
    country: "Ukraina",
    persent: "95",
  },
  {
    country: "Russland",
    persent: "80",
  },
  {
    country: "Polen",
    persent: "78",
  },
  {
    country: "Romania",
    persent: "60",
  },
  {
    country: "Serbia",
    persent: "30",
  },
  {
    country: "Moldova",
    persent: "20",
  },
];

export const CONTACTS_OFFICE: Array<string> = ["Akersgata 32", "0180 Oslo"];
export const CONTACTS_COMPANY_NUMBER: Array<string> = ["926 850 679"];
export const CONTACTS_PHONE: Array<string> = ["+47 240 22 22 8"];
export const CONTACTS_MAIL: Array<string> = ["kontakt@yne.no"];

interface ServiceItem {
  title: string;
  sub_title: string;
  specialization: any;
  short_desc: string;
  full_desc: React.ReactNode;
  link: string;
}

export const SERVICE_FULLSTACK: ServiceItem = {
  title: "Fullstack-utvikling",
  sub_title: "Skreddersydde digitale løsninger fra front til bak",
  specialization: [],
  short_desc:
    "Fullstack-utvikling viser til evnen til å arbeide med både frontend og backend av en programvare eller nettside. En fullstack-utvikler har kunnskap om både klient-siden (HTML, CSS, JavaScript, m.m.) og server-siden (databaser, server-teknologier, m.m.), noe som gjør dem i stand til å håndtere hele utviklingsprosessen. ",
  full_desc: <Fullstack />,
  link: "/tjenester/fullstack",
};

export const SERVICE_DEVOPS: ServiceItem = {
  title: "DevOps",
  sub_title:
    "Optimalisering av digitale økosystemer og infrastruktur med våre DevOps-eksperter.",
  specialization: [],
  short_desc:
    "Vi kan skytjenester og automatisering av bygg og deploy. Våre konsulenter har praktisk erfaring i hvordan man med å bruke DevOps-prinsipper og -praksiser i sitt daglige virke har fått til økt effektivitet, raskere og mer verdiskapende leveranser og reduserte kostnader. Vi har erfaring med både Azure og AWS.",
  full_desc: <Devops />,
  link: "/tjenester/devops",
};

export const SERVICE_DESIGN: ServiceItem = {
  title: "Design",
  sub_title:
    "Visuelle mesterverk og unike konseptuelle opplevelser skapt av våre design-eksperter",
  specialization: [],
  short_desc:
    "Et tidsriktig og stilig design er avgjørende for å lykkes med å kommunisere det man ønsker. Vi hjelper deg med å lage en god  brief, noe som gjør det enklere for våre designere å levere til forventningene. Våre designere er vant til korte tidsfrister men  lar ikke dette gå utover kvaliteten. Ta gjerne kontakt selv om prosjektet har kort tid til deadline.",
  full_desc:
    "Et godt resultat skapes gjennom en rask og effektiv prosess, der vi tar med oss kunden inn i prosessen. Ut kommer tanker, idèer og konsepter. Våre kunder skal føle seg ivaretatt og prioritert. Våre hovedtjenester er Identitet, Web design, illustrasjoner og infographics. I tillegg til disse leverer vi 3D visualisering, karakter trykk, print-trykk, ikoner, design til sosiale medier, presentasjonsdesign og animasjonsklipp.",
  link: "/tjenester/design",
};

export const SERVICE_COMMERCE: ServiceItem = {
  title: "E-Commerce",
  sub_title: "",
  specialization: [],
  short_desc:
    "Yne har lang erfaring med E-commerce, blant annet fra nordens største aktør Komplett.no. Vi hjelper våre kunder med å finne den beste og mest moderne tekniske plattformen til deres behov.",
  full_desc:
    "Vi bygger din nettbutikk fra scratch og tilpasser dine behov. Vi har erfaring med ecommerce fra nordens støYne har lang erfaring fra å sette opp nettbutikker med Shopify som plattform. Shopify er en av de ledende plattformene innen E-handel i Norge. Vi har også jobbet med større løsninger, blant annet Komplett.no som er nordens største aktør. Yne utvikler din nettbutikk fra bunnen av og tilpasser oss alltid etter dine behov. Med mange års erfaring fra E-commerce, har vi kompetanse til å sette opp en komplett netthandelsløsning. Om du ønsker hjelp til å skrive ut pakkelapper via nettbutikken er også dette noe vi kan bistå med. Ta gjerne kontakt med oss når du er i idéfasen, så hjelper vi deg i gang.",
  link: "/tjenester/commerce",
};

export const SERVICE_MOBILE: ServiceItem = {
  title: "Mobil",
  sub_title: "",
  specialization: [],
  short_desc:
    "Yne utvikler brukervennlige mobilapplikasjoner av høy teknisk kvalitet. Vi utvikler applikasjoner til både iOS og Android.",
  full_desc:
    "Yne har solid kompetanse innen apputvikling. Vi utvikler mobilapplikasjoner av høy teknisk kvalitet og god brukervennlighet. Vi designer og utvikler apper til iOS og Android ved hjelp av de mest moderne verktøy som er tilgjengelig,  noe som er med på å gjøre prosessen tidseffektiv og mindre kostbar. Vi hjelper deg i hele prosessen fra idé til ferdig utviklet app. Det er også mulig å få levert app-design før utviklingen finner sted. Ønsker du å vite mer? Ta gjerne kontakt med oss, så hjelper vi deg.",
  link: "/tjenester/mobil",
};

export const SERVICE_FRONT_END: ServiceItem = {
  title: "Frontend",
  sub_title: "Imponerende brukeropplevelser med våre frontend eksperter",
  specialization: [],
  short_desc:
    "Frontend er koden for det visuelle. Det er rammeverket som trigger hva som skjer når du bruker elementer på en nettside eller app, for eksempel hvis du trykker på en knapp. Vi hjelper våre kunder innenfor en rekke tekniske plattformer og hjelper til med å finne den mest moderne teknologien for ditt prosjekt.",
  full_desc: <Frontend />,
  link: "/tjenester/frontend",
};

export const SERVICE_RÅDGIVNING: ServiceItem = {
  title: "Rådgivning",
  sub_title: "Veiledning for å drive din suksess til neste nivå",
  specialization: [],
  short_desc:
    "Vi har kompetente rådgivere som kan hjelpe til med IT-tjenester og digitalisering der det er behov. Våre rådgivere har jobbet i mange år innenfor forskjellige domener, men vi har spesielt lang erfaring innenfor netthandel og fintech.",
  full_desc: <Rådgivning />,
  link: "/tjenester/raadgivning",
};

export const SERVICE_BACK_END: ServiceItem = {
  title: "Backend",
  sub_title: "Vår backend-ekspertise, din digitale kraft",
  specialization: [],
  short_desc:
    "Vi leverer gode tekniske løsninger innenfor en rekke plattformer. Våre erfarne utviklere er vant til å gjøre komplekse problemstillinger om til smidige løsninger.",
  full_desc: <Backend />,
  link: "/tjenester/backend",
};

export const CORE_TEAM: ICardProps[] = [
  {
    image:
      "https://res.cloudinary.com/yneno/image/upload/v1656580033/about-page/employees/bv_ehs9bv.jpg",
    image_webp:
      "https://res.cloudinary.com/yneno/image/upload/v1656580033/about-page/employees/bv_qa3vqn.webp",
    name: "Bjørn-Vegard Thoresen",
    occupation: "Salgsleder, Partner",
    desc: "Bjørn Vegard har har lang fartstid i IT bransjen. Han har de siste årene jobbet som selvstendig utvikler for store norske selskap. Bjørn har derfor lang erfaring med salg, samt utvikling. Innenfor utvikling har det gått mye i Microsoft C#, men også frontend. Bjørn verdsetter gode kunderelasjoner og smidige prosjekt der alt går etter planen.",
    phone: "+47 480 04 433",
    mail: "bjorn@yne.no",
  },
  {
    image:
      "https://res.cloudinary.com/yneno/image/upload/v1656580034/about-page/employees/david_dwblth.jpg",
    image_webp:
      "https://res.cloudinary.com/yneno/image/upload/v1656580034/about-page/employees/david_kuljal.webp",
    name: "David Bakke",
    occupation: "",
    desc: "",
    mail: "david@yne.no",
  },
  {
    image:
      "https://res.cloudinary.com/yneno/image/upload/v1656580032/about-page/employees/thomas_pvvjph.jpg",
    image_webp:
      "https://res.cloudinary.com/yneno/image/upload/v1656580033/about-page/employees/thomas_ynstxs.webp",
    name: "Thomas Holen",
    occupation: "Leder for Teknologi- og forretningsutvikling, Partner",
    desc: "Thomas har lang erfaring som teknisk leder. Han jobbet i mange år som teknisk team lead for Nordens største ecommerce aktør. Etter dette har han vært med på å starte et større konsulenthus, i tillegg til å ha jobbet som selvstendig utvikler. På den tekniske siden er Thomas aller best på frontend, men han har også dyp innsikt både på design og backend.",
    phone: "+47 970 94 565",
    mail: "thomas@yne.no",
  },
  {
    image:
      "https://res.cloudinary.com/yneno/image/upload/v1656580033/about-page/employees/njaal_vyrunu.jpg",
    image_webp:
      "https://res.cloudinary.com/yneno/image/upload/v1656580033/about-page/employees/njaal_fdocnm.webp",
    name: "Njaal Almestad Gjerde",
    occupation: "Daglig leder, Partner",
    desc: "Njaal har lang erfaring både med å drive selskap, men også fra å jobbe som utvikler. Njaal var med å starte opp suksess appen Mattilbud og han har jobbet som IT konsulent. Som konsulent har Njaal jobbet i flere år som selvstendig utvikler innenfor frontend. Njaal har en mastergrad fra Informatikk på NTNU.",
    phone: "+47 922 16 752",
    mail: "njaal@yne.no",
  },
  {
    image:
      "https://res.cloudinary.com/yneno/image/upload/v1656580032/about-page/employees/andreas_e8znnm.jpg",
    image_webp:
      "https://res.cloudinary.com/yneno/image/upload/v1656580032/about-page/employees/andreas_ifgyfp.webp",
    name: "Andreas Storesund Madsen",
    occupation: "Fagansvarlig. Kotlin/Java/Web",
    desc: ".",
    phone: "",
    mail: "andreas@yne.no",
  },
  {
    image:
      "https://res.cloudinary.com/yneno/image/upload/v1656580033/about-page/employees/august_nyhtxj.jpg",
    image_webp:
      "https://res.cloudinary.com/yneno/image/upload/v1656580033/about-page/employees/august_exizm8.webp",
    name: "August Lund Eilertsen",
    occupation: "Fullstack/Frontend",
    desc: ".",
    phone: "",
    mail: "august@yne.no",
  },
  {
    image:
      "https://res.cloudinary.com/yneno/image/upload/v1656580034/about-page/employees/brage_phmvtm.jpg",
    image_webp:
      "https://res.cloudinary.com/yneno/image/upload/v1656580033/about-page/employees/brage_dnki8w.webp",
    name: "Brage Allum",
    occupation: "Fullstack Java/Web",
    desc: ".",
    phone: "",
    mail: "brage@yne.no",
  },
  {
    image:
      "https://res.cloudinary.com/yneno/image/upload/v1656593266/about-page/employees/joachim_u1qzdv.jpg",
    image_webp:
      "https://res.cloudinary.com/yneno/image/upload/v1656593266/about-page/employees/joachim_ja9dhf.webp",
    name: "Joachim Nakken",
    occupation: "Frontend",
    desc: ".",
    phone: "",
    mail: "joachim@yne.no",
  },
  {
    image:
      "https://res.cloudinary.com/yneno/image/upload/v1656580033/about-page/employees/sigurd_mgraeg.jpg",
    image_webp:
      "https://res.cloudinary.com/yneno/image/upload/v1656580034/about-page/employees/sigurd_ptccqi.webp",
    name: "Sigurd Ofstad",
    occupation: "Fullstack Java/Web",
    desc: ".",
    phone: "",
    mail: "sigurd@yne.no",
  },
  {
    image:
      "https://res.cloudinary.com/yneno/image/upload/v1656580034/about-page/employees/stian_liqgi0.jpg",
    image_webp:
      "https://res.cloudinary.com/yneno/image/upload/v1656580033/about-page/employees/stian_ptcuqb.webp",
    name: "Stian Bøe",
    occupation: "Fullstack/Tech lead .NET/web",
    desc: ".",
    phone: "+47 481 39 101",
    mail: "stian@yne.no",
  },
  {
    image:
      "https://res.cloudinary.com/yneno/image/upload/v1656580032/about-page/employees/tharin_ur5hnh.jpg",
    image_webp:
      "https://res.cloudinary.com/yneno/image/upload/v1656580032/about-page/employees/tharin_knqgjv.webp",
    name: "Tharin Chobkaphol",
    occupation: "Frontend",
    desc: ".",
    phone: "",
    mail: "tharin@yne.no",
  },
  {
    image:
      "https://res.cloudinary.com/yneno/image/upload/v1656580032/about-page/employees/tonje_fysiz2.jpg",
    image_webp:
      "https://res.cloudinary.com/yneno/image/upload/v1656580033/about-page/employees/tonje_bpcibm.webp",
    name: "Tonje Kristiansen",
    occupation: "HR/Admin",
    desc: ".",
    phone: "478 50 886",
    mail: "tonje@yne.no",
  },
];

export const MONTH_NAMES: Array<string> = [
  "Januar",
  "Februar",
  "Mars",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Desember",
];

export const ESTIMATION_OPTIONS = {
  projectType: [
    {
      type: "E-handel",
      slug: "ecommerce",
      baseTeam: ["frontend", "backend"],
    },
    {
      type: "Mobil-app",
      slug: "mobileapp",
      baseTeam: ["cross"],
    },
    {
      type: "Web-app",
      slug: "webapp",
      baseTeam: ["frontend", "backend"],
    },
    {
      type: "Destinasjonsside + CMS",
      slug: "landingpagecms",
      baseTeam: ["frontend"],
    },
  ],
  additionalInfo: [
    {
      name: "projectManager",
      label: "I need a project manager to handle it for me",
    },
    {
      name: "teamLead",
      label:
        "I need a team lead to set up architecture, tech stack and leading team",
    },
    {
      name: "desiner",
      label: "I need design",
    },
  ],
  members: [
    {
      label: "Frontend Developer",
      value: "frontend",
      currentLocation: {
        title: "Remote (Ukraina)",
        value: "remote",
      },
      location: [
        {
          title: "Remote (Ukraina)",
          value: "remote",
        },
        {
          title: "Norge",
          value: "norway",
        },
      ],
      quantity: 0,
    },
    {
      label: "Backend Developer",
      value: "backend",
      currentLocation: {
        title: "Remote (Ukraina)",
        value: "remote",
      },
      location: [
        {
          title: "Remote (Ukraina)",
          value: "remote",
        },
        {
          title: "Norge",
          value: "norway",
        },
      ],
      quantity: 0,
    },
    {
      label: "iOS Developer",
      value: "ios",
      currentLocation: {
        title: "Remote (Ukraina)",
        value: "remote",
      },
      location: [
        {
          title: "Remote (Ukraina)",
          value: "remote",
        },
        {
          title: "Norge",
          value: "norway",
        },
      ],
      quantity: 0,
    },
    {
      label: "Android Developer",
      value: "android",
      currentLocation: {
        title: "Remote (Ukraina)",
        value: "remote",
      },
      location: [
        {
          title: "Remote (Ukraina)",
          value: "remote",
        },
        {
          title: "Norge",
          value: "norway",
        },
      ],
      quantity: 0,
    },
    {
      label: "Cross platform mobile app Developer",
      value: "cross",
      currentLocation: {
        title: "Remote (Ukraina)",
        value: "remote",
      },
      location: [
        {
          title: "Remote (Ukraina)",
          value: "remote",
        },
        {
          title: "Norge",
          value: "norway",
        },
      ],
      quantity: 0,
    },
  ],
};

export const CLIENTS_LOGO = [
  {
    url: axo_finans_logo_bw,
    alt: "axo finans",
    width: 60,
    height: 60,
  },
  {
    url: lt_logo_bw,
    alt: "Luftfarstilsynet",
    width: 200,
    height: 35,
  },
  {
    url: bufdir_logo_bw,
    alt: "bufdir",
    width: 60,
    height: 60,
  },
  {
    url: bank_logo_bw,
    alt: "bank norwegian",
    width: 200,
    height: 35,
  },
  {
    url: sector_logo_bw,
    alt: "sector_alarm",
    width: 92,
    height: 60,
  },
  {
    url: spond_logo_bw,
    alt: "spond",
    width: 142,
    height: 32,
  },
  {
    url: morgenlevering_logo_bw,
    alt: "morgenlevering",
    width: 200,
    height: 50,
  },
  {
    url: ruter_logo_bw,
    alt: "Ruter",
    height: 60,
    width: 133,
  },
];

export const TEAM_TO_CONTACT = [
  {
    image:
      "https://res.cloudinary.com/yneno/image/upload/v1706793457/employee-portraits/njaal_yvs5n7.png",
    image_webp:
      "https://res.cloudinary.com/yneno/image/upload/v1706793458/employee-portraits/njaal_wt4jjl.webp",
    name: "Njaal Almestad Gjerde",
    subtitle: "Jeg er tilgjengelig for resterende forespørsler",
    title: "Daglig leder og rekrutteringsansvarlig",
    phone: "+47 922 16 752",
    email: "njaal@yne.no",
  },
  {
    image:
      "https://res.cloudinary.com/yneno/image/upload/v1706793457/employee-portraits/thomas_vyxjqr.png",
    image_webp:
      "https://res.cloudinary.com/yneno/image/upload/v1706793457/employee-portraits/thomas_mg4nbj.webp",
    name: "Thomas Holen",
    subtitle: "Snakk om tekniske løsninger med meg",
    title: "CFO & Leder for Teknologi- og forretningsutvikling",
    phone: "+47 970 94 565",
    email: "thomas@yne.no",
  },
  {
    image:
      "https://res.cloudinary.com/yneno/image/upload/v1706793457/employee-portraits/bv_uxrelt.png",
    image_webp:
      "https://res.cloudinary.com/yneno/image/upload/v1706793457/employee-portraits/bv_os6df7.webp",
    name: "Bjørn Vegard Thoresen",
    subtitle: "Ta kontakt med meg rundt prosjektmuligheter",
    title: "Leder for salg og kunderelasjon",
    phone: "+47 480 04 433",
    email: "bjorn@yne.no",
  },
];

export const CLOUDINARY_IMG = {
  HOME: {
    yne_illustration:
      "https://res.cloudinary.com/yneno/image/upload/w_1500,f_auto,q_auto/assets/yne-illustration_zpulye.jpg",
    yne_illustration_christmas:
      "https://res.cloudinary.com/yneno/image/upload/v1703148525/assets/dhi26yldyfdapirje8o4.png",
    yne_illustration_newyear:
      "https://res.cloudinary.com/yneno/image/upload/v1703148524/assets/izhjfpxmfniingyvutbu.png",
    team_jump:
      "https://res.cloudinary.com/yneno/image/upload/w_1500,f_auto,q_auto/Yne_no/yne-jump_f0fzzp.jpg",
    team_party:
      "https://res.cloudinary.com/yneno/image/upload/w_1500,f_auto,q_auto/v1704373548/gruppebildet_redigert_versjon_2_gzckc6.jpg",
    holding_hands_illustration:
      "https://res.cloudinary.com/yneno/image/upload/w_1500,f_auto,q_auto/assets/Several_emojis_holding_hands_2.jpg",
  },
  JOB: {
    hero_image:
      "https://res.cloudinary.com/yneno/image/upload/v1726742215/Jobb-Page/IMG_3047_1_tq4xst.jpg",
    karl_johans_gate:
      "https://res.cloudinary.com/yneno/image/upload/w_1000,f_auto,q_auto/Jobb-Page/20210604_234702_kntc9x.jpg",
    party_group_image:
      "https://res.cloudinary.com/yneno/image/upload/v1692994237/slack-etc/ynefest_xqtwhf.jpg",
    laptop_character:
      "https://res.cloudinary.com/yneno/image/upload/w_1000,f_auto,q_auto/Jobb-Page/6_ie7pb0.png",
    girls:
      "https://res.cloudinary.com/yneno/image/upload/v1672998648/Jobb-Page/DSC05706_soh1i0.jpg",
    august_dj:
      "https://res.cloudinary.com/yneno/image/upload/v1672999068/Jobb-Page/sommerfest-82_lirju5.jpg",
    frilans:
      "https://res.cloudinary.com/yneno/image/upload/v1715683650/Jobb-Page/brage_og_andreas_nwhtny.jpg",
  },
  ABOUT: {
    portrait:
      "https://res.cloudinary.com/yneno/image/upload/w_1000,f_auto,q_auto/assets/IMG_1641_qgpt0p.jpg",
    task_illustration:
      "https://res.cloudinary.com/yneno/image/upload/w_1000,f_auto,q_auto/assets/stats_image_mugtfq.png",
    yne_family:
      "https://res.cloudinary.com/yneno/image/upload/w_1000,f_auto,q_auto/about-page/yne-familien_x0un36.jpg",
  },
};

export const MAGIC_NUMBER_60 = 60;
export const MEDIUM_BREAKPOINT = 840;

export const MAGIC_NUMBER_EIGHT = 8;
export const MAGIC_NUMBER_NINE = 9;

export const NUMBER_OF_YEARS = {
  0: MAGIC_NUMBER_NINE,
  1: MAGIC_NUMBER_EIGHT,
  2: MAGIC_NUMBER_EIGHT,
};

export const NO_FOOTER_PAGES: string[] = ["/kontakt-oss", "/personalhandbok"];

export const ANNUAL_NUMBER_OF_INVOICEABLE_HOURS = 1695;
export const PERCENTAGE_OF_INVOICES_FOR_EMPLOYEE_SALARY = [
  39, 42, 45, 47.5, 50, 54, 57, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 70, 70,
] as const;
export const fixedSalaryTable = [
  540000, 566000, 618000, 643000, 690000, 768000, 815000, 865000, 883000,
  915000, 947000, 977000, 998000, 1017000, 1035000, 1040000, 1050000,
] as const;
export const teknaItAverage = [
  0, 600000, 609233, 654161, 706857, 752120, 806094, 832727, 878999, 899831,
  929499, 969076, 956770, 972039, 1058320, 1007080, 1046057,
] as const;

const BACKGROUND_FULL_VARIANTS = ["full"];
export const BACKGROUND_LEFT_VARIANTS = ["left", ...BACKGROUND_FULL_VARIANTS];
export const BACKGROUND_RIGHT_VARIANTS = ["right", ...BACKGROUND_FULL_VARIANTS];
export const BACKGROUND_IMAGE_VARIANTS = ["image", ...BACKGROUND_FULL_VARIANTS];

export const LINKEDNIN_FORM_ERROR_MESSAGE =
  "Du må skrive inn en gyldig Linkedin link";

const TYPEWRITER_TIME_BETWEEN = 2000;

export const HOME_TYPEWRITER_SEQUENCE = [
  ` Kotlin`,
  TYPEWRITER_TIME_BETWEEN,
  ` Java`,
  TYPEWRITER_TIME_BETWEEN,
  ` C#`,
  TYPEWRITER_TIME_BETWEEN,
  ` React`,
  TYPEWRITER_TIME_BETWEEN,
  ` DevOps`,
  TYPEWRITER_TIME_BETWEEN,
  ` CI/CD`,
  TYPEWRITER_TIME_BETWEEN,
  ` Smidig utvikling`,
  TYPEWRITER_TIME_BETWEEN,
  ` Systemutvikling`,
  TYPEWRITER_TIME_BETWEEN,
  ` Universell utforming`,
  TYPEWRITER_TIME_BETWEEN,
  ` Responsivt design`,
  TYPEWRITER_TIME_BETWEEN,
  ` Skytjenester`,
  TYPEWRITER_TIME_BETWEEN,
  ` Testdrevet utvikling`,
  TYPEWRITER_TIME_BETWEEN,
  ` Nytenking`,
  TYPEWRITER_TIME_BETWEEN,
  ` Problemløsning`,
  TYPEWRITER_TIME_BETWEEN,
  ` Systemarkitektur`,
  TYPEWRITER_TIME_BETWEEN,
  ` Tverrfaglig samarbeid`,
  TYPEWRITER_TIME_BETWEEN,
  ` API-utvikling`,
  TYPEWRITER_TIME_BETWEEN,
];
