import React, { useState } from "react";
import styles from "./contact-form.module.scss";
import { BaseInput, TextAreaInput } from "../inputs";
import { FormButton } from "../buttons";
import { UploadButton } from "../buttons/upload-button";
import { NavLink } from "react-router-dom";
import crose from "../../assets/icons/crose.svg";
import { LINKEDNIN_FORM_ERROR_MESSAGE } from "../../helpers/const";

interface ContactFormData extends HTMLFormElement {
  email: HTMLInputElement;
  melding: HTMLInputElement;
  linkedin: HTMLInputElement;
  file: HTMLInputElement;
}

interface ContactFormEvent extends React.FormEvent<ContactFormData> {
  target: ContactFormData;
}

const ContactForm = ({ title }: { title: string }) => {
  const [msg, setMsg] = useState("");
  const [error, setError] = useState<string | null>(null);
  const handleClick = () => setMsg("");

  const handleSubmit = (
    e: {
      preventDefault: () => void;
      target: ContactFormData;
    },
    file: File
  ) => {
    e.preventDefault();
    setError(null);
    if (!e.target) {
      return;
    }

    const form = e.target;
    const formData = new FormData();
    formData.append("title", title);
    formData.append("email", form.email.value);
    formData.append("melding", form.melding.value);
    formData.append("linkedin", form.linkedin.value);
    formData.append("file", file);
    formData.append("form-name", "jobb");

    fetch("/", {
      method: "POST",
      body: formData,
    })
      .then(() => setMsg("Takk for at du tok kontakt med oss!"))
      .catch((error) => alert(error));
  };

  const handleValidation = (e: ContactFormEvent) => {
    e.preventDefault();

    if (!e.currentTarget) return;
    const form: ContactFormData = e.target;
    const LinkedInValue = (e.currentTarget.linkedin as HTMLInputElement).value;
    const regex = /linkedin.com\/in/;
    if (!regex.test(LinkedInValue)) {
      setError(LINKEDNIN_FORM_ERROR_MESSAGE);
      return;
    }

    const fileList = form.file.files;

    if (fileList === null || fileList.length !== 1) {
      setError("Vennligst last opp din CV");
      return;
    }

    const file: File = fileList[0];

    handleSubmit(e, file);
  };

  return (
    <div className={styles.form_wrapper}>
      <h3>{title}</h3>

      <form onSubmit={handleValidation} name="jobb" method="post">
        <BaseInput
          label="E-post *"
          type="email"
          name="email"
          placeholder="Epost"
          required
        />
        <TextAreaInput
          label="Melding *"
          name="melding"
          placeholder="Melding"
          required
        />
        <BaseInput
          label="Linkedin link *"
          type="text"
          name="linkedin"
          placeholder="Linkedin link"
          required
        />
        <UploadButton label="CV" name="file" onChange={() => setError(null)} />
        <span className={styles.errorWrapper}>
          {error && <span className={styles.error}>{error}</span>}
        </span>
        <FormButton />
      </form>

      {msg && (
        <div className={styles.success_wrapper}>
          <div className={styles.success_message}>
            <NavLink to="/" onClick={handleClick}>
              <img src={crose} alt="close" />
            </NavLink>
            <h1>{msg}</h1>
            <p>
              Vi setter pris på at du kontaktet oss. En av våre kolleger vil
              straks ta kontakt med deg!
            </p>

            <p>Ha en fortsatt fin dag!</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
