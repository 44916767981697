export const EMPLOYER_TAX = 0.141;
export const EMPLOYER_TAX_ABOVE_850K = 0.191;

/**
 * Beregner provisjonslønn for en ansatt basert på timepris og prosentsats av provisjon for 2023.
 * Tar hensyn til økt arbeidsgiveravgift for lønn over 850k.
 * @param hourlyRate Timepris
 * @param hoursWorked Antall timer arbeidet (vanligvis ca 1695 timer)
 * @param provisionPercentage Provisjonsprosent
 * @returns Total lønnsopptjening for det aktuelle året, inkludert feriepengene som utbetales påfølgende år.
 * */
export const calculateYearlyCommissionSalary2023 = ({
  hourlyRate,
  hoursWorked,
  commission,
}: {
  hourlyRate: number;
  hoursWorked: number;
  commission: number;
}) => {
  /**
   * Symboler:
   * aga = arbeidsgiveravgift
   * a = aga 14.1%F
   * b = aga 19.1%
   * c = lønnsgrense før økt arbeidsgiveravgift, dvs. 850k
   * g = "grunnlag", dvs. timepris * antall timer * provisjonsprosent
   * l = lønn
   * Utledning av formel:
   * Anta at l > 850k:
   * aga = g - l                  grunnlag minus lønn
   * aga = ac + b(l - c)          14.1% av 850k + 19.1% av alt over 850k
   * g - l = ac + b(l - c)
   * l(1+b) = g - ac + bc  = g - c(a + b)
   * l = (g + c(b - a)) / (1 + b)
   * */
  const a = EMPLOYER_TAX;
  const b = EMPLOYER_TAX_ABOVE_850K;
  const c = 850_000;
  const g = (hourlyRate * hoursWorked * commission) / 100;
  const salaryBefore2023 = g / (1 + a);
  return Math.round(
    salaryBefore2023 <= c ? salaryBefore2023 : (g + c * (b - a)) / (1 + b)
  );
};

export const calculateYearlyCommissionSalarySimple = ({
  hourlyRate,
  hoursWorked,
  commission,
}: {
  hourlyRate: number;
  hoursWorked: number;
  commission: number;
}) => {
  const a = EMPLOYER_TAX;
  const g = (hourlyRate * hoursWorked * commission) / 100;
  return Math.round(g / (1 + a));
};
